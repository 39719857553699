// Site variables
export let site = {
    name: 'Sex Shop Erotic24',
    motto: {
        ro: 'Dorinta * Fantezie * Pasiune',
        en: 'Lust * Fantasy * Passion'
    },
    pages: {
        home: {
            title: {
                ro: 'Sex shop Erotic24.ro - Dorinta * Fantezie * Pasiune',
            },
            description: {
                ro: 'Explorați plăcerea și îndrăzneala cu Erotic24.ro, magazinul online al pasiunii și erotismului. Găsiți o gamă variată de produse erotice de înaltă calitate, de la jucării intime la lenjerie seducătoare. Aduceți-vă viața sexuală la un nivel superior cu experiența discretă și livrare rapidă de la Erotic24.ro',
            }
        }
    },
    about: {
        ro: 'Erotic24 a fost creat din pasiunea pentru erotism și plăcere. Ne dorim să oferim produse de calitate superioară la prețuri accesibile, iar livrarea să fie rapidă și discretă.',
        en: 'Erotic24 was created from the passion for eroticism and pleasure. We want to offer superior quality products at affordable prices, and delivery to be fast and discreet.'
    },
    showNewsletterPopup: true,
    showNewsletterDiscountText: true,
}

// Site languages
export let languages = [
    {
        iso_693_1_code: 'ro',
        iso_name: 'Romana',
        flag: '<svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-ro" viewBox="0 0 512 512"><g fill-rule="evenodd" stroke-width="1pt"><path fill="#00319c" d="M0 0h170.7v512H0z"/><path fill="#ffde00" d="M170.7 0h170.6v512H170.7z"/><path fill="#de2110" d="M341.3 0H512v512H341.3z"/></g></svg>'
    },
];

// 2Performant
export let tooperformant = {
    enabled: false,
    campaign_unique: '60b800055',
    confirm: '760e31c7029dc5f8'
}

// Store configuration
export let store = {
    product_max_quantity: 10000,
    limitMaxQuantity(value) {
        return (value > this.product_max_quantity) ? this.product_max_quantity : value},
    gift_wrap_uuid: '2933ec47-b4eb-315c-8b2f-85d4348f',
    free_shipping_threshold: 150,
    product_tile_button_template: 'button',
    add_to_cart_confirmation_popup : true,
    add_to_cart_quantity_controls : false,
    clear_cart_button : false,
    checkout_type: 'three_step_checkout',
    checkout_hide_shipping: false,
    checkout_show_vat : false,
    product_page : {
        product_details_tabs : [
            'description','specs','faq'
        ],
        show_discreet_shipping: true,
        show_shipping_cost : true,
        show_phone_numbers : true,
    }
}


export let footer_navigation_1_data = {
    'ro': [
        {
            title: 'Detalii livrare',
            route: {
                name: null,
                slug: 'detalii-livrare'
            },
            children: []
        },
        {
            title: 'Retur si Garantie',
            route: {
                name: null,
                slug: 'retur-si-garantie'
            },
            children: []
        },
        {
            title: 'Rezolvarea alternativa a litigiilor',
            route: {
                name: null,
                slug: null,
                url: 'https://anpc.ro/ce-este-sal/'
            },
            children: []
        },
        {
            title: 'Rezolvarea online a litigiilor',
            route: {
                name: null,
                slug: null,
                url: 'https://anpc.ro/ce-este-sal/'
            },
            children: []
        },
    ],
    'en': [
        {
            title: 'Delivery details',
            route: {
                name: null,
                slug: 'delivery-details'
            },
            children: []
        },
        {
            title: 'Return and Warranty',
            route: {
                name: null,
                slug: 'return-and-warranty'
            },
            children: []
        },
        {
            title: 'Alternative dispute resolution',
            route: {
                name: null,
                slug: null,
                url: 'https://anpc.ro/ce-este-sal/'
            },
            children: []
        },
        {
            title: 'Online dispute resolution',
            route: {
                name: null,
                slug: null,
                url: 'https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage'
            },
            children: []
        },
    ]
}


export let footer_navigation_2_data = {
    'ro': [
        {
            title: 'FAQ',
            route: {
                name: null,
                slug: 'faq'
            },
            children: []
        },
        {
            title: 'Contactati-ne',
            route: {
                name: null,
                slug: 'contact'
            },
            children: []
        },
        {
            title: 'Termeni si conditii',
            route: {
                name: null,
                slug: 'termeni-si-conditii'
            },
            children: []
        },
        {
            title: 'Date cu caracter personal',
            route: {
                name: null,
                slug: 'date-cu-caracter-personal'
            },
            children: []
        },
        {
            title: 'Blog',
            route: {
                name: null,
                slug: 'blog'
            },
            children: []
        }
    ],
    'en': [
        {
            title: 'FAQ',
            route: {
                name: null,
                slug: 'faq'
            },
            children: []
        },
        {
            title: 'Contact us',
            route: {
                name: null,
                slug: 'contact-us'
            },
            children: []
        },
        {
            title: 'Terms and conditions',
            route: {
                name: null,
                slug: 'terms-and-conditions'
            },
            children: []
        },
        {
            title: 'Personal data',
            route: {
                name: null,
                slug: 'personal-data'
            },
            children: []
        },
        {
            title: 'Blog',
            route: {
                name: null,
                slug: 'blog'
            },
            children: []
        }
    ]
}

export let contact_information = {

    email: 'comenzi@erotic24.ro',
    phones: [
        {
            'label': '0376.448.039',
            'value': '0376448039'
        },
        {
            'label': '0730.556.787',
            'value': '0730556787'
        }
    ],
    whatsapp_phone_number: '+40730556787',
    show_whatsapp_button: true,
    program: {
        ro: 'Zilnic 8:30 - 20:30',
        en: 'Daily 8:30 - 20:30'
    }
}